<template>
  <div>
    <b-modal
      v-model="modalShow"
      :ok-disabled="isDisabledAction"
      :ok-title="modal.type === 'approve' ? 'Approve' : 'Reject'"
      :ok-variant="modal.type === 'approve' ? 'success' : 'danger'"
      :title="modal.title"
      centered
      @ok="modal.action"
    >
      <p class="pt-2 pb-1">
        {{ modal.description }}
      </p>
      <template v-if="modal.type === 'approve'">
        <b-form-group v-if="modal.key ==='sof'">
          <label>Available account Balance in USDT based on all provided documents</label>
          <b-form-input
            v-model="sof"
            placeholder="Enter account balance in USDT"
            type="number"
          />
        </b-form-group>
      </template>

      <b-form-group v-if="modal.type === 'reject' && typeof modal.key === 'string'">
        <label>Reason</label>
        <b-form-select
          v-model="inputReason"
          :options="reasonOptions"
        />
      </b-form-group>
      <b-form-group
        description="Please, enter the last name of the applicant to confirm."
      >
        <label>Customer’s last name</label>
        <b-form-input
          v-model="inputName"

          placeholder="Enter customer’s last name"
          type="text"
        />
      </b-form-group>
    </b-modal>
    <template v-if="lists.length">
      <div
        :id="link"
        class="anchor"
      />

      <b-row v-if="title || description">
        <b-col
          md="10"
          xl="5"
        >
          <h4 v-if="title">
            {{ title }}
          </h4>
          <p v-if="description">
            {{ description }}
          </p>
        </b-col>
        <b-col
          md="2"
          xl="7"
        >
          <slot name="button" />
        </b-col>
      </b-row>
      <template v-for="(list, key) in lists">

        <b-card
          v-if="list.type === 'default'"
          :key="key"
          no-body
        >
          <b-list-group flush>
            <b-list-group-item
              v-for="item in list.list"
              :key="item.title + key.toString()"
            >
              <b-row>
                <b-col
                  md="11"
                  xl="11"
                >
                  <b-row>
                    <b-col
                      v-if="item.title"
                      md="6"
                      xl="3"
                    >
                      <b>{{ item.title }}</b>
                    </b-col>
                    <b-col
                      :md="!['note'].includes(item.type) ? 6 : 12"
                      :xl="!['note'].includes(item.type) ? 7 : 12"
                    >
                      <span v-if="item.type === 'default'">{{ item.value || 'n/a' }}</span>
                      <KycStatus
                        v-else-if="item.type === 'status'"
                        :status="item.value"
                      />
                      <b-badge
                        v-else-if="item.type === 'badge'"
                        :variant="item.value.color "
                      >
                        {{ item.value.status }}
                      </b-badge>
                      <div
                        v-else-if="item.type === 'data'"
                        class="code"
                      >{{ JSON.stringify(item.value, null, 5) }}
                      </div>
                      <div v-else-if="item.type=== 'actions'" />
                      <span v-else>
                        {{ item.value }}
                      </span>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col
                  md="1"
                  xl="1"
                >
                  <div
                    v-if="item.type=== 'actions'"
                    class="d-flex align-items-center justify-content-end w-100"
                  >
                    <b-button
                      v-if="status !== 'verified'"
                      class="text-left ml-1"
                      type="button"
                      variant="success"
                      @click="onClickApprove(item.value)"
                    >
                      Approve
                    </b-button>
                    <b-button
                      v-if="status !== 'rejected'"
                      class="text-left ml-1"
                      type="button"
                      variant="danger"
                      @click="onClickReject(item.value)"
                    >
                      Reject
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </b-card>
        <b-card
          v-else-if="list.list.files"
          :key="key"
          no-body
        >
          <b-list-group flush>
            <b-list-group-item
              v-for="item in list.list.files"
              :key="item + key.toString()"
            >
              <b-row>
                <b-col
                  md="11"
                  xl="11"
                >
                  <b-row>
                    <b-col
                      v-if="list.list.type || list.list.documentType"
                      md="6"
                      xl="3"
                    >
                      <b>{{ convertCamelCaseToCapitalizedSpaces(list.list.documentType || list.list.type) }}</b>
                    </b-col>
                    <b-col
                      v-if="list.list.status"
                      md="6"
                      xl="3"
                    >
                      <KycStatus
                        :status="list.list.status"
                      />
                    </b-col>
                    <b-col
                      v-if="list.list.createdAt"
                      md="6"
                      xl="3"
                    >
                      <b>{{ list.list.createdAt }}</b>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col
                  md="1"
                  xl="1"
                >
                  <div class="w-100 d-flex flex-row align-items-center justify-content-md-end">
                    <feather-icon
                      class="cursor-pointer"
                      icon="EyeIcon"
                      size="21"
                      @click="onViewFile(item)"
                    />
                  </div>
                  <!--                <b>{{ item }}</b>-->
                </b-col>
              </b-row>

            </b-list-group-item>
          </b-list-group>
        </b-card>
      </template>
    </template>
    <div
      v-if="isPermissionButton"
      class="d-flex align-items-center justify-content-end w-100 mb-2"
    >
      <b-button
        v-if="isEnableApproveButton"
        class="text-left ml-1"
        type="button"
        variant="success"
        @click="onClickApprove({key: +$route.params.id, id: null, requestId: null, title: 'identity verification'})"
      >
        Approve
      </b-button>
      <b-button
        v-if="!isEnableApproveButton || isChangeable"
        class="text-left ml-1"
        type="button"
        variant="danger"
        @click="onClickReject({key: +$route.params.id, id: null, requestId: null, title: 'identity verification'})"
      >
        Reject
      </b-button>
    </div>
  </div>
</template>
<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BListGroup,
  BListGroupItem,
  BRow,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import KycStatus from '@/views/apps/kyc/components/status.vue'
import waitRequest from '@/mixins/waitRequest'
import { kucSections, KycRejectReasons } from '@/views/apps/kyc/config/kycTypes'
import checkErrorsAlert from '@/mixins/checkErrorsAlert'

export default {
  name: 'KycCheck',
  components: {
    ToastificationContent,
    BFormSelect,
    BFormInput,
    BButton,
    KycStatus,
    BBadge,
    BCard,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BFormGroup,
  },
  mixins: [waitRequest, checkErrorsAlert],
  props: {
    status: {
      type: String,
      default: '',
    },
    isEnableApproveButton: {
      type: Boolean,
      default: false,
    },
    isShowGlobalButton: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    lists: {
      type: Array,
      default: () => [],
    },
    link: {
      type: String,
      default: '',
    },
    mainData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      kucSections,
      KycRejectReasons,
      modalShow: false,
      modal: {
        title: '',
        description: '',
        type: '',
        key: '',
        action: () => {
        },
      },
      sof: '',
      inputName: '',
      inputReason: '',
    }
  },
  computed: {
    ...mapGetters({
      listOwnerPermissions: 'users/listOwnerPermissions',
    }),
    isChangeable() {
      return !!this.listOwnerPermissions.includes('kyc-change-status')
    },
    isPermissionButton() {
      if (!this.isShowGlobalButton) return false
      if (this.isChangeable) {
        return this.mainData.status !== 'reject'
      }
      if (this.mainData?.responsible?.id !== this.$store.getters['auth/admin'].id) return false
      return !['reject', 'verified'].includes(this.mainData.status)
    },
    reasonOptions() {
      if (!this.KycRejectReasons[this.link]) return []
      return this.KycRejectReasons[this.link].map(reason => ({
        value: reason,
        text: this.convertCamelCaseToCapitalizedSpaces(reason),
      }))
    },
    isDisabledAction() {
      if (this.inputName.toLowerCase() !== this.mainData?.applicantInfo?.lastName.toLowerCase()) return true
      if (this.modal.type === 'reject') {
        if (this.inputReason === '' && typeof this.modal.key === 'string') return true
      } else if (this.modal.type === 'approve') {
        if (this.modal.key === 'sof') {
          if (this.sof === '') return true
        }
      }

      return false
    },
  },
  mounted() {

    // console.log(this.kucSections.every(section => {
    //   const { key } = section
    //   const target = this.mainData.checks[key]
    //   console.log({ target })
    //   if ((target?.status || target?.[0]?.status) === 'verified') return true
    //   return false
    // }))
    // console.log(Object.keys(this.mainData.checks).every(key => {
    //   const target = this.mainData.checks[key]
    //   console.log(target)
    //   return true
    // }))
    // console.log(this.mainData.checks)
  },
  methods: {
    ...mapActions({
      setActions: 'kyc/setActions',
      getLink: 'kyc/getLink',
    }),

    onViewFile(path) {
      this.waitRequest(async () => {
        await this.getLink({ path }).then(({ data }) => {
          window.open(data.url, '_blank')
        }).catch(this.checkErrorsAlert)
      })
    },
    resetInputs() {
      this.sof = ''
      this.inputName = ''
      this.inputReason = ''
    },
    onClickApprove({
      key = null, id = null, requestId = null, title,
    }) {
      const url = `/kyc/${key}/approve`
      this.resetInputs()
      this.modal = {
        title: `Approve ${title}`,
        description: 'Are you sure you want to approve this KYC check? \n'
          + 'Please ensure you’ve reviewed all the documents and details carefully before proceeding.',
        type: 'approve',
        key,
        action: () => {
          this.sendRequest(url, id, requestId)
        },
      }
      this.modalShow = true
    },
    onClickReject({
      key = null, id = null, requestId = null, title,
    }) {
      this.resetInputs()

      const url = `/kyc/${key}/reject`
      this.modal = {
        title: `Reject ${title}`,
        description: 'Please select rejection reason. This reason will be sent to the user for their reference.',
        type: 'reject',
        key,
        action: () => {
          this.sendRequest(url, id, requestId, this.inputReason)
        },
      }
      this.modalShow = true
    },
    sendRequest(url, id = null, requestId = null, reason = null) {
      const params = { url }
      if (id || requestId) {
        const data = {}
        if (url.includes('sof/approve')) data.maxAmount = Number(this.sof)
        if (id) {
          data.id = id
        }
        if (requestId) {
          data.requestId = requestId
        }
        if (reason) {
          data.reason = reason
        }
        params.data = data
      }

      this.waitRequest(async () => {
        await this.setActions(params).then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Applicant Information is updated',
              icon: 'UserPlusIcon',
              variant: 'info',
            },
          })

          this.$emit('onChange')
        }).catch(this.checkErrorsAlert)
      })
    },
    convertCamelCaseToCapitalizedSpaces(str) {
      return str.replace(/([A-Z])/g, ' $1').toLowerCase().replace(/^\w/, char => char.toUpperCase())
    },
  },
}
</script>
<style lang="scss" scoped>
.anchor {
  position: relative;
  top: -100px;
  visibility: hidden;
}
.code {
  background-color: initial !important;
  white-space: pre;
}
</style>
